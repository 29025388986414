<template>
  <v-form ref="validate">
    <v-container>
      <Loading v-if="isLoading" />
      <v-row>
        <v-col cols="12">
          <v-card
            class="mb-4"
            elevation="0"
            outlined>
            <v-card-subtitle class="d-flex justify-space-between">
              <h4 class="header-primary">
                Order ID
              </h4>
              <h4 class="data">
                {{ $route.params.orderId }}
              </h4>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle
              v-if="order.isGroup"
              class="d-flex justify-space-between">
              <h5 class="header-primary">
                {{ $t('global.typeBooking') }}
              </h5>
              <h5 class="data">
                {{ $t('global.group') }}
              </h5>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle class="d-flex justify-space-between">
              <h5 class="header-primary">
                {{ $t('global.branch') }}
              </h5>
              <h5 class="data">
                {{ order.branch === 'trang' ? $t('global.trang') : $t('global.sathon') }}
              </h5>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle class="d-flex justify-space-between">
              <h5 class="header-primary">
                {{ $t('global.type') }}
              </h5>
              <h5 class="data">
                {{ $t(`type.${order.type}`) }}
              </h5>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle class="d-flex justify-space-between">
              <h5 class="header-primary">
                {{ $t('global.status') }}
              </h5>
              <h5
                :class="statusColor(order.orderStatus)">
                {{ $t(`history.orderStatus.${order.orderStatus}`) }}
              </h5>
            </v-card-subtitle>
            <!-- <v-card-subtitle
              v-if="!order.isGroup"
              class="d-flex justify-space-between">
              <h5 class="header-primary-small">
                {{ $t('global.atkStatusDetailShort') }}
              </h5>
              <h5
                v-if="order.atkStatus"
                :class="atkStatusColor(order.atkStatus)">
                {{ $t(`global.atkStatusTrue`) }}
              </h5>
              <h5
                v-else
                :class="atkStatusColor(order.atkStatus)">
                {{ $t(`global.atkStatusFalse`) }}
              </h5>
            </v-card-subtitle> -->
            <v-divider></v-divider>
            <v-card-subtitle class="d-flex justify-space-between">
              <h5 class="header-primary">
                {{ $t('global.date') }}
              </h5>
              <h5 class="data">
                {{ order.schedule.date | formatMiniDate }}
              </h5>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle class="d-flex justify-space-between">
              <h5 class="header-primary">
                {{ $t('global.time') }}
              </h5>
              <h5
                class="data">
                {{ formatSchedule(order.schedule.startTime, order.schedule.endTime) }}
              </h5>
            </v-card-subtitle>
            <v-divider></v-divider>

            <v-card-subtitle>
              <h5 class="header-primary">
                {{ $t('booking.bookingInfo') }}
              </h5>
              <v-container class="mt-3">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.name') }}
                    </h5>
                    <h5
                      v-if="!order.isGroup"
                      class="data">
                      {{ order.member.firstname }} {{ order.member.lastname }}
                    </h5>
                    <h5
                      v-else
                      class="data">
                      {{ order.contactFirstname }} {{ order.contactLastname }}
                    </h5>
                  </v-col>
                </v-row>
                <v-row v-if="order.isGroup">
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <v-col class="px-0 py-0">
                      <h5 class="header-secondary">
                        {{ $t('global.organizationName') }}
                      </h5>
                    </v-col>
                    <v-col class="px-0 py-0">
                      <h5
                        class="data text-right">
                        {{ order.organizationName }}
                      </h5>
                    </v-col>
                  </v-col>
                </v-row>
                <v-row v-if="order.isGroup">
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.tel') }}
                    </h5>
                    <h5
                      class="data text-right">
                      {{ order.contactTel }}
                    </h5>
                  </v-col>
                </v-row>
                <v-row v-if="order.isGroup">
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.email') }}
                    </h5>
                    <h5
                      class="data text-right">
                      {{ order.contactEmail }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('booking.visitConsent') }}
                    </h5>
                    <div
                      v-if="order.verifyNote"
                      class="d-flex">
                      <h5
                        v-if="!file"
                        class="data text-right green--text text-bold mr-3"
                        @click="openLink(order.verifyNote)">
                        {{ $t('global.view') }}
                      </h5>
                      <h5
                        v-if="!file"
                        class="data text-right blue--text text-bold"
                        @click="handleFileImport">
                        {{ $t('global.edit') }}
                      </h5>
                    </div>
                    <h5
                      v-if="!file && !order.verifyNote"
                      class="data text-right blue--text text-bold"
                      @click="handleFileImport">
                      {{ $t('booking.attachFile') }}
                    </h5>
                    <div
                      v-if="file"
                      class="d-flex">
                      <h5
                        class="data text-right green--text text-bold mr-2"
                        @click="updatePdf">
                        {{ $t('booking.upload') }}
                      </h5>
                      <h5
                        class="data text-right red--text text-bold"
                        @click="file = ''">
                        {{ $t('booking.clear') }}
                      </h5>
                    </div>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="application/pdf"

                      @change="onFileChanged($event)"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle>
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex justify-space-between">
                  <h5 class="header-secondary">
                    {{ $t('booking.participantTotal') }}
                  </h5>
                  <h5
                    v-if="!order.isGroup"
                    class="header-secondary">
                    {{ order.participant.length }}
                  </h5>
                  <h5
                    v-else
                    class="header-secondary">
                    {{ order.joinQty }}
                  </h5>
                </v-col>
              </v-row>
              <v-container
                v-if="!order.isGroup"
                class="mt-3">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('ageGroup.young') }}
                    </h5>
                    <h5 class="data">
                      {{ order.price.countYoung }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('ageGroup.student') }}
                    </h5>
                    <h5 class="data">
                      {{ order.price.countStudent }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('ageGroup.adult') }}
                    </h5>
                    <h5 class="data">
                      {{ order.price.countAdult }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('ageGroup.senior') }}
                    </h5>
                    <h5 class="data">
                      {{ order.price.countSenior }}
                    </h5>
                  </v-col>
                </v-row>
              </v-container>
              <v-container
                v-else
                class="mt-3">
                <v-row
                  v-for="item in order.participants"
                  :key="item.range">
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ item.range }} {{ $t('global.year') }}
                    </h5>
                    <h5 class="data">
                      {{ item.count }}
                    </h5>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle v-if="!order.isGroup">
              <h5 class="header-primary">
                {{ $t('booking.bookingCustomer') }}
              </h5>
              <v-container
                v-for="item in order.participant"
                :key="item._id"
                class="mt-3">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.name') }}
                    </h5>
                    <h5 class="data">
                      {{ item.firstname }} {{ item.lastname }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.age') }}
                    </h5>
                    <h5 class="data">
                      {{ item.birthday | formatAge }} {{ $t('global.year') }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.type') }}
                    </h5>
                    <h5 class="data">
                      {{ $t(`ageGroup.${item.ageGroup }`) }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.price') }}
                    </h5>
                    <h5 class="data">
                      ฿{{ formatPrice(item.ageGroup) | formatCurrency }}
                    </h5>
                  </v-col>
                  <v-col
                    v-if="item.note"
                    cols="12"
                    class="pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.note') }}
                    </h5>
                    <h5 class="data text-right">
                      {{ item.note }}
                    </h5>
                  </v-col>
                </v-row>
                <v-divider class="mt-3"></v-divider>
              </v-container>
            </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-subtitle
              v-if="!order.isGroup"
              ref="payment">
              <h5 class="header-primary">
                {{ $t('global.summary') }}
              </h5>
              <v-container
                class="mt-3">
                <v-row>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting' && point > 0"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <div class="d-flex">
                      <v-switch
                        v-model="useDiscount"
                        :disabled="point <= 0"
                        color="#3BAEF8"
                        class="py-0 my-0"
                        :label="`${$t('global.usePoint')}(${$t('global.have')} ${point} ${$t('global.point')} )`"
                        @change="switchUsePoint()">
                        >
                      </v-switch>
                    </div>
                    <div>
                      <v-text-field
                        v-model="usePoint"
                        :disabled="!useDiscount"
                        type="number"
                        :min="0"
                        :rules="maxRule"
                        :label="$t('global.amountToUse')"
                        style="width: 8rem"
                        class="py-0 my-0"></v-text-field>
                    </div>
                  </v-col>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting' && packageItems.length > 0"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <v-row>
                      <v-col
                        v-if="packageItems.length > 0"
                        class="my-0 py-0 mb-2"
                        cols="12">
                        <v-switch
                          v-model="usePackage"
                          :disabled="packageItems.length <= 0"
                          color="#3BAEF8"
                          class="py-0 my-0"
                          :label="`${$t('global.usePackage')} (${$t('global.have')} ${packageItems.length} ${$t('global.package')} )`"
                          @change="switchUsePackage()">
                          >
                        </v-switch>
                      </v-col>
                      <v-col
                        v-if="usePackage"
                        class="my-0 py-0 mb-6"
                        cols="12">
                        <v-select
                          v-model="selectedPackage"
                          class="my-0 py-0"
                          item-text="text"
                          item-value="value"
                          :no-data-text="$t('packages.notFound')"
                          :items="packageItems"
                          @change="selectPackage($event)"></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-card
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting' && usePackage && packageItems.length > 0"
                    class="py-4 px-4 my-0 mb-4"
                    width="100%">
                    <div class="font-weight-bold">
                      {{ $t('packages.packageDetail') }}
                    </div>
                    <div class="my-1">
                      {{ $t('packages.packageName') }} : {{ packages.title }}
                    </div>
                    <div class="mb-1">
                      {{ $t('packages.balance') }} : {{ packages.quatity }}
                    </div>
                    <div class="mb-1">
                      <div v-if="packages.expired">
                        <div v-if="$i18n.locale == 'th'">
                          {{ $t('packages.expired') }} : {{ packages.expired | formatDateTime }}
                        </div>
                        <div v-else>
                          {{ $t('packages.expired') }} : {{ packages.expired | formatDateTimeEn }}
                        </div>
                      </div>
                      <div v-else>
                        {{ $t('packages.expired') }} : -
                      </div>
                    </div>
                    <div class="mb-1">
                      {{ $t('packages.maximumPrice') }} : ฿{{ packages.minPrice | formatCurrency }}
                    </div>
                    <div>
                      {{ $t('packages.status') }} :
                      <span
                        v-if="packages.minPrice >= order.price.total"
                        class="green--text font-weight-bold">{{ $t('packages.available') }}</span>
                      <span
                        v-else
                        class="red--text font-weight-bold">{{ $t('packages.notAvailable') }} ({{ $t('packages.totalHighlight') }})</span>
                    </div>
                  </v-card>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting' && packageItems.length > 0"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.discountPackage') }}
                    </h5>
                    <h5 class="data">
                      -฿{{ discountPackage | formatCurrency }}
                    </h5>
                  </v-col>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting'"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.priceBeforeDiscount') }}
                    </h5>
                    <h5 class="data">
                      ฿{{ order.price.total | formatCurrency }}
                    </h5>
                  </v-col>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting' && point > 0"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.pointsUsed') }}
                    </h5>
                    <h5 class="data">
                      {{ discount.point | formatCurrency }}
                    </h5>
                  </v-col>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting' && point > 0"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.discountPoint') }}
                    </h5>
                    <h5 class="data">
                      -฿{{ discount.money | formatCurrency }}
                    </h5>
                  </v-col>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.orderStatus === 'waiting'"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.discountPromotion') }} ({{ promotion }}%)
                    </h5>
                    <h5
                      class="data">
                      -฿{{ discountPromotion | formatCurrency }}
                    </h5>
                  </v-col>
                  <v-col
                    v-if="order.type !== 'group' && order.price.total > 0 && order.branch !== 'trang'"
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.discountAdultAndChildren') }}
                    </h5>
                    <h5
                      class="data">
                      -฿{{ order.price.total - total | formatCurrency }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-space-between pr-0">
                    <h5 class="header-secondary">
                      {{ $t('global.summary') }}
                    </h5>
                    <h5 class="data">
                      ฿{{ total | formatCurrency }}
                    </h5>
                  </v-col>
                </v-row>
                <v-divider class="mt-3"></v-divider>
              </v-container>
            </v-card-subtitle>
          </v-card>
          <div v-if="!order.isGroup && !isRejectOrCalcel && !isExpired">
            <v-btn
              v-if="order.price.total > 0 && order.orderStatus === 'waiting'"
              :disabled="usePoint > point || packageuse "
              color="primary"
              class="mb-2"
              block
              @click="checkout()">
              <v-icon left>
                mdi-credit-card-outline
              </v-icon>
              {{ $t('global.checkout') }}
            </v-btn>
          </div>
          <!-- <v-btn
            v-if="!order.isGroup && order.atkStatus === false && !isRejectOrCalcel && !isExpired"
            color="success"
            class="mb-2"
            block
            depressed
            :to="`/atk/${this.$route.params.orderId}`">
            <v-icon left>
              mdi-send-circle-outline
            </v-icon>
            {{ $t('global.sendAtk') }}
          </v-btn> -->
          <!-- <v-btn
            v-if="!order.isGroup && order.atkStatus === true && !isRejectOrCalcel && !isExpired"
            color="success"
            class="mb-2"
            block
            depressed
            :to="`/atk/${this.$route.params.orderId}`">
            <v-icon left>
              mdi-account-details-outline
            </v-icon>
            {{ $t('global.atkDetail') }}
          </v-btn> -->
          <!-- <a
            v-if="!order.isGroup && !isRejectOrCalcel && !isExpired"
            target="_blank"
            class="text-decoration-none"
            :href="`https://calendar.google.com/calendar/r/eventedit?text=Plan+Toys&dates=${startDate}/${endDate}&details=This+is+Detail`"
          >
            <v-btn
              block
              color="#3BAEF8"
              class="white--text mb-2"
              depressed>
              <v-icon left>
                mdi-calendar-month-outline
              </v-icon>
              {{ $t('global.addToCalendar') }}
            </v-btn>
          </a> -->
          <!-- <v-btn
            v-if="!order.isGroup && isChangeSchedule && !isExpired"
            color="orange"
            dark
            block
            depressed
            :to="`/history/change/${this.$route.params.orderId}?type=${order.type}`">
            <v-icon left>
              mdi-repeat
            </v-icon>
            {{ $t('booking.changeSchedule') }}
          </v-btn> -->
          <!-- <v-btn
            v-if="order.isGroup && !isRejectOrCalcel && !isExpired"
            color="
            primary"
            class="mb-2"
            block
            depressed
            :to="`/history/file/${this.$route.params.orderId}`">
            <v-icon left>
              mdi-file-document
            </v-icon>
            {{ $t('booking.visitConsentSend') }}
          </v-btn> -->
          <!-- <v-btn
            v-if="order.isGroup && !isRejectOrCalcel && !isExpired"
            color="orange"
            dark
            block
            depressed
            :to="`/history/change/${this.$route.params.orderId}?type=group`">
            <v-icon left>
              mdi-repeat
            </v-icon>
            {{ $t('booking.changeSchedule') }}
          </v-btn> -->
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import Loading from '@/components/Loading.vue'
import dayjs from 'dayjs'
import axios from '../../../../../utils/axios.js'

export default {
  components: {
    Loading
  },
  data () {
    return {
      isLoading: false,
      useDiscount: false,
      selectedPackage: '',
      order: {
        orderStatus: '',
        member: {
          firstname: '',
          lastname: ''
        },
        schedule: {
          date: '',
          startTime: '',
          endTime: ''
        },
        participant: [],
        price: {
          priceYoung: 0,
          priceStudent: 0,
          priceAdult: 0,
          priceSenior: 0,
          totalPriceAdult: 0,
          totalPriceYoung: 0,
          total: 0
        }
      },
      schedule: {},
      moneyToPoint: [
        { money: 0, point: 0 }
      ],
      pointToMoney: [
        { point: 0, money: 0 }
      ],
      usePackage: false,
      usePackageAmount: 0,
      usePoint: 0,
      point: '',
      member: {
        package: '',
        memberId: ''
      },
      packages: {
        title: '',
        quatity: 0,
        expired: '',
        minPrice: 0
      },
      packagesMember: [],
      file: '',
      publicUrl: '',
      adultQty: 0
    }
  },
  computed: {
    isRejectOrCalcel () {
      if (this.order.orderStatus === 'rejected' || this.order.orderStatus === 'canceled') {
        return true
      }
      return false
    },
    isChangeSchedule () {
      if (this.order.orderStatus === 'waiting' || this.order.orderStatus === 'paid') {
        return true
      }
      return false
    },
    isExpired () {
      const diff = dayjs(this.order.schedule.date).diff(dayjs(), 'day')
      if (diff < 0) {
        return true
      }
      return false
    },
    maxRule () {
      return [(v) => (v && this.point >= v) || this.$t('global.notEnoughPoints')]
    },
    maxPackageRule () {
      return [(v) => (v && this.member.package >= v) || this.$t('global.notEnoughPackage')]
    },
    promotion () {
      if (!this.useDiscount && this.schedule.options) {
        return this.schedule.options.promotion.percent || 0
      }
      return 0
    },
    discount () {
      if (this.useDiscount) {
        const closest = this.pointToMoney.reduce((prev, curr) => {
          const a = (Math.abs(curr.point - this.usePoint) < Math.abs(prev.point - this.usePoint) && curr.point <= this.usePoint ? curr : prev)
          return a
        })
        if (this.usePoint < closest.point) {
            return { money: 0, point: 0 }
          }
          if (this.usePoint > this.point) {
            return { money: 0, point: 0 }
        }
        return { money: closest.money, point: closest.point }
      }
      return { money: 0, point: 0 }
    },
    discountPackage () {
      if (this.usePackage && this.packages.minPrice >= this.order.price.total) {
        return this.order.price.totalPriceAdult
      }
      return 0
    },
    packageuse () {
      if (this.usePackage && this.order.price.total > this.packages.minPrice) {
        return true
      }
      return false
    },
    discountPromotion () {
      if (!this.useDiscount && this.schedule.options) {
        const { total } = this.order.price
        const result = (total * this.promotion) / 100
        return result
      }
      return 0
    },
    total () {
      if (this.order.branch === 'trang') {
        if (this.discount || this.discountPackage) {
          const t = this.order.price.total - this.discountPackage
          const total = t - this.discount.money
          const a = 100 - this.promotion
          const result = (total * a) / 100
          if (result < 0) return 0
          return result
        }
        return this.order.price.total
      }
      // if (this.order.branch === 'sathorn') {
      //   let priceTotal = this.order.price.total
      //    const youngAndStudent = this.order.price.countYoung + this.order.price.countStudent
      //   const countAudult2 = this.order.price.countAdult - youngAndStudent
      //   if (countAudult2 <= 0) {
      //     const seinorSubCountAudult = this.order.price.countSenior - Math.abs(countAudult2)
      //     if (seinorSubCountAudult <= 0) {
      //       priceTotal = 0
      //     }
      //     if (seinorSubCountAudult > 0) {
      //       priceTotal = seinorSubCountAudult * this.order.price.priceSenior
      //     }
      //   }
      //   if (countAudult2 > 0) {
      //     const price = this.order.price.priceAdult * Math.abs(countAudult2)
      //     const seniorPrice = this.order.price.priceSenior * this.order.price.countSenior | 0
      //     priceTotal = price + seniorPrice
      //   }
      //   return priceTotal
      // }
       if (this.order.branch === 'sathorn') {
        let priceTotal = this.order.price.total
         const youngAndStudent = this.order.price.countYoung + this.order.price.countStudent
        const countAudult2 = this.order.price.countAdult - youngAndStudent
        if (countAudult2 <= 0) {
          const seinorSubCountAudult = this.order.price.countSenior - Math.abs(countAudult2)
          if (seinorSubCountAudult <= 0) {
            priceTotal = this.order.price.countYoung * this.order.price.priceYoung + this.order.price.countStudent * this.order.price.priceStudent
          }
          if (seinorSubCountAudult > 0) {
            priceTotal = this.order.price.countYoung * this.order.price.priceYoung + this.order.price.countStudent * this.order.price.priceStudent + seinorSubCountAudult * this.order.price.priceSenior
          }
        }
        if (countAudult2 > 0) {
          const price = this.order.price.priceAdult * Math.abs(countAudult2)
          const seniorPrice = this.order.price.priceSenior * this.order.price.countSenior
          priceTotal = price + seniorPrice + this.order.price.countYoung * this.order.price.priceYoung + this.order.price.countStudent * this.order.price.priceStudent
        }
        return priceTotal
      }
      return 0
    },
    packageItems () {
      if (this.packagesMember.length > 0) {
        const packagesFilter = this.packagesMember.filter((item) => {
          const minute = dayjs(item.package.expired).diff(dayjs(), 'minute')
          return item.package.items > 0 && minute > 0
        })
        const arr = []
        for (let i = 0; i < packagesFilter.length; i++) {
          arr.push({ text: packagesFilter[i].package.title, value: packagesFilter[i].memberPackageId })
        }
        const result = arr.map((item) => `${item.text.slice(0, 50)} ${item.text.length >= 40 ? '....' : ''}`)
        return result
      }
      return []
    },
    startDate () {
      if (this.order.schedule.date && !this.order.isGroup) {
        const startTime = this.order.schedule.startTime.split(':')
        const date = new Date(this.order.schedule.date)
        return new Date(date.setHours(startTime[0], startTime[1])).toISOString().replace(/([.:-])/g, '')
      }
      return ''
    },
    endDate () {
      if (this.order.schedule.date && !this.order.isGroup) {
        const endTime = this.order.schedule.endTime.split(':')
        const date = new Date(this.order.schedule.date)
        return new Date(date.setHours(endTime[0], endTime[1])).toISOString().replace(/([.:-])/g, '')
      }
      return ''
    }
  },
  async mounted () {
    // this.checkLogined()
    await this.getOrder()
    await this.getMember()
    await this.getPoint()
    await this.getAllPackageMember()
    if (this.$route.query.actions === 'payment') {
       const el = this.$refs.payment
      setTimeout(() => {
        el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      }, 200)
    }
  },
  methods: {
    statusColor (value) {
      switch (value) {
        case 'waiting':
          return 'orange--text data'
        case 'pending':
          return 'orange--text data'
        case 'paid':
          return 'green--text data'
        case 'canceled':
          return 'red--text data'
        case 'rejected':
          return 'red--text data'
        default:
          return ''
      }
    },
    formatPrice (type) {
      switch (type) {
        case 'young':
          return this.order.price.priceYoung
        case 'student':
          return this.order.price.priceStudent
        case 'adult':
          return this.order.price.priceAdult
        case 'senior':
          return this.order.price.priceSenior
        default:
          return 0
      }
    },
    atkStatusColor (value) {
      switch (value) {
        case true:
          return 'green--text data'
        case false:
          return 'orange--text data'
        default:
          return ''
      }
    },
    switchUsePackage () {
      this.usePackageAmount = 0
    },
    switchUsePoint () {
      this.usePoint = 0
    },
    checkout () {
        const { orderId } = this.$route.params
        const payload = {
          orderId,
          memberId: this.member.memberId,
          point: this.discount.point,
          package: this.selectedPackage,
          discount: this.discount.money,
          total: this.total
        }
        localStorage.setItem('order', JSON.stringify(payload))
        this.$router.push(`/2c2p/${orderId}`)
    },
    selectPackage (event) {
      const arr = this.packagesMember.filter((item) => item.memberPackageId === event)
      if (arr.length > 0) {
        this.packages.title = arr[0].package.title
        this.packages.quatity = arr[0].package.items
        this.packages.expired = arr[0].package.expired
        this.packages.minPrice = arr[0].package.minPrice
      }
    },
    async getPoint () {
      try {
        const { data: { data } } = await axios.get(`/setting/point?branch=${this.order.branch}`)
        this.moneyToPoint = data.moneyToPoint
        this.pointToMoney = data.pointToMoney
      } catch (error) {
        console.error(error)
      }
    },
    async getMember () {
      try {
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await axios(`/member/facebook/${member.facebookId}`)
        if (data.member) {
          this.point = data.member.point
          this.member.package = data.member.package
          this.member.memberId = data.member.memberId
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getOrder () {
      try {
        this.isLoading = true
        const { type } = this.$route.query
        const normalUrl = `${process.env.VUE_APP_API_URL}/order/${this.$route.params.orderId}`
        const groupUrl = `${process.env.VUE_APP_API_URL}/order-group/${this.$route.params.orderId}`
        const url = type === 'normal' ? normalUrl : groupUrl
        const { data: { data } } = await this.axios.get(url)
        const schedule = await axios.get(`/schedule/${data.schedule.scheduleId}`)

        this.schedule = schedule.data.data

        this.order = data
        if (type === 'normal') {
          this.adultQty = data.participant.filter((member) => member.ageGroup === 'Adult').length
        }
        this.isLoading = false
      } catch (error) {
        console.error(error)
      }
    },
    async getAllPackageMember () {
      try {
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await axios.get(`/package-member/${member.memberId}/member?package.branch=${this.order.branch}`)
        if (data.length > 0) {
          const filterGetterZero = data.filter((item) => item.package.items > 0)
          this.packagesMember = filterGetterZero
          this.selectedPackage = filterGetterZero[0].memberPackageId
          this.packages.title = filterGetterZero[0].package.title
          this.packages.quatity = filterGetterZero[0].package.items
          this.packages.expired = filterGetterZero[0].package.expired
          this.packages.minPrice = filterGetterZero[0].package.minPrice
        }
      } catch (error) {
        console.error(error)
      }
    },
    async checkLogined () {
      try {
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/member/${member.memberId}`)
        if (!data.member) {
          localStorage.removeItem('member')
          this.$router.push('/')
        }
      } catch (error) {
        console.error(error)
      }
    },
    openLink (link) {
      window.open(link)
    },
    onFileChanged (event) {
      if (event.target.files[0]) {
        this.file = event.target.files[0]
        this.$refs.uploader.value = ''
      } else {
        this.file = ''
      }
    },
    handleFileImport () {
      this.$refs.uploader.click()
    },
    async updatePdf () {
      try {
        this.isLoading = true
        if (this.file) {
          const formData = new FormData()
          formData.append('file', this.file)
          const { data: { data } } = await this.axios({
            method: 'post',
            url: `${process.env.VUE_APP_API_URL}/upload`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
          })
          this.publicUrl = data.publicUrl
        }

        await this.axios({
          method: 'patch',
          url: `${process.env.VUE_APP_API_URL}/order-group/${this.$route.params.orderId}`,
          data: { verifyNote: this.publicUrl }
        })
        this.file = ''
        this.publicUrl = ''
        this.getOrder()
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
    wrapText (val) {
      if (!val || val === 'undefined') return val
      return val.toString().slice(0, 30)
    },
    formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    }
  }
}
</script>

<style scoped>
.header-primary {
  font-size: 18px;
  font-weight: 500;
}
.header-primary-small {
  font-size: 16px;
  font-weight: 500;
}
.header-secondary {
  font-size: 16px;
  font-weight: 500;
}
.data {
  font-size: 16px;
  font-weight: 500;
}
.text-bold {
  font-weight: 600;
}
</style>
